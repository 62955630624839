import React, { Component } from "react";
import Navbar from "../../components/navbar";
import * as projectStyles from "../../styles/css/project.module.css";
import ads_screen from "../../styles/images/idsucreShop.png";
import ads_product from "../../styles/images/idsucre.png";
import ads_services from "../../styles/images/idsucreBis.png";
import ads_mobile from "../../styles/images/idsucreMobile.png";

class Idsucre extends Component {
    render(){
        return (
            <React.Fragment>
                <Navbar color="white"/>
                <div>
                    <div className={projectStyles.back}>
                        <div id="idsucrebanner"></div>
                    </div>
                    <div className={projectStyles.banner}>
                        <div>
                            <span style={{fontStyle:"italic"}}>Refonte du site vitrine id-sucre.com</span>
                            <h1 style={{fontSize: "6em", textTransform:"uppercase"}}>ID SUCRE</h1>
                            <span style={{color:"#DCDCDC", textTransform:"uppercase", fontSize: "0.8em"}}>Type de projet</span>
                            <span style={{fontSize: "0.8em"}}>Boutique en ligne</span>
                        </div>
                    </div>
                    <div style={{background:"white"}}>
                    <div className={projectStyles.wrapper}>
                        <div className={projectStyles.container}>
                            <div>
                                <ul className={projectStyles.details}>
                                    <li>Client</li>
                                    <li>Studio CQEG</li>
                                </ul>
                                <ul className={projectStyles.details}>
                                    <li>Projet</li>
                                    <li>Refonte de la boutique en ligne d'ID SUCRE</li>
                                </ul>
                                <ul className={projectStyles.details}>
                                    <li>Date</li>
                                    <li>12/20</li>
                                </ul>
                            </div>
                            <div>
                                <p>
                                    ID SUCRE, pâtissier et chocolatier à Laval à contacter le Studio CQEG afin de mettre au goût du jour
                                    sa boutique en ligne pour les fête de noël 2020. Le Studio CQEG se chargeant de la partie webdesign, nous a contacter afin
                                    de nous a confié le développement du site e-commerce.
                                </p>
                                <p>
                                    Ce projet réalisé sous Prestashop fut un vrai travail de collaboration afin de tenir les délais limités.
                                </p>
                                <a href="https://www.id-sucre.com/"  target="_blank" rel="noreferrer" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>Voir le site</a>
                            </div>
                        </div>
                        <img src={ads_screen} className={projectStyles.example} alt={"Page d'accueil du site ads53.com"}></img>
                        <img src={ads_services} className={projectStyles.example} alt={"Page services du ads53.com"}></img>
                        <div className={projectStyles.mobile}>
                            <img src={ads_product} className={projectStyles.product} alt={"Page taille ordinateur d'un produit du site ads53.com"}></img>
                            <img src={ads_mobile} className={projectStyles.productMobile} alt={"Page taille mobile d'un produit du site ads53.com"}></img>
                        </div>
                    </div>
                    </div>
                </div>
            </React.Fragment>  
        )
    }
}

export default Idsucre;